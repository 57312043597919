<template>
  <div v-if="assetType === 'video'">
    <video-component
      :videoUrl="assetUrl"
      :videoAutoplay="videoAutoplay"
      :videoControls="videoControls"
      :videoLoop="videoLoop"
      :assetHeight="assetHeight"
      :width="cardWidth"
    />
  </div>
  <div v-else-if="assetType === 'image'">
    <image-component :imageUrl="assetUrl" :height="assetHeight" :width="cardWidth"/>
  </div>
  <div v-else>
  </div>
</template>

<script>

import ImageComponent from '../assets/ImageComponent.vue'
import VideoComponent from '../assets/VideoComponent.vue'

export default {
  name: 'AssetComponent',
  props: {
    assetUrl: String,
    videoAutoplay: Boolean,
    videoControls: Boolean,
    videoLoop: Boolean,
    assetType: String,
    assetHeight: String,
    cardWidth: String
  },
  components: {
    ImageComponent, VideoComponent
  },
  computed: {
    GetDate () {
      return this.date.toDate().toLocaleDateString('de-DE')
    }
  }
}
</script>
<style lang="scss">
@import '../../assets/styles/_variables.scss';

</style>
