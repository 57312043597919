import CVideo from '../cAssets/CVideo'
import CImage from '../cAssets/CImage'

export default class AssetHelper {
  name: string

  constructor () {
    this.name = 'AssetHelper'
  }

  public createAsset (format: string, Id?: string) {
    if (format === 'mp4') {
      return new CVideo(Id)
    } else if (['png', 'jpg'].includes(format)) {
      return new CImage(Id)
    } else if (format === 'image/jpeg') {
      return new CImage()
    } else if (format === 'video/mp4') {
      return new CVideo()
    } else {
      console.log('Unknown asset format')
    }
  }
}
