<template>
  <div class="sticky bottom-0 bg-ft-upload-color justify-center">
    <v-overlay :value="imagePreviewURL" :opacity="1">
      <v-btn @click="CancelPreview">
        <v-icon>
        mdi-close-circle
        </v-icon>
      </v-btn>
      <asset-component
       :videoAutoplay="false"
       :videoControls="true"
       :videoLoop="false"
       :assetHeight="'500'"
       :assetUrl="imagePreviewURL"
       :assetType="assetType"
       :cardWidth="'380'"
      />
    </v-overlay>
    <v-row v-if="showProgress" class="justify-center">
      <v-progress-linear :value="progress">
      </v-progress-linear>
    </v-row>
    <v-row class="justify-center">
      <v-file-input
          prepend-icon="mdi-camera"
          v-model="file"
          hide-input
          class="inline mt-4"
          accept="image/*, video/*"
          @change="ImagePreview"
      />
      <v-divider
        vertical
        class="mt-4 mb-4"
      ></v-divider>
      <v-textarea
        v-model="message"
        solo
        flat
        :height="40"
        style="width:300px;font-size:20px;"
        class="ml-2 mt-2 pt-2 z-10"
        placeholder="Deine Nachricht"
        maxlength="100"
        :counter="100"
      />
      <v-btn
        fab
        dark
        class="bg-ft-upload-button mb-3 z-20"
        @click="Upload"
        absolute
        style="margin-top: -20px; margin-right: -300px;"
        >
        <v-icon dark>
          mdi-send
        </v-icon>
        </v-btn>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import Post from '../../post/Post'
import AssetHelper from '../../cAssets/AssetHelper'
import User from '../../user/User'
import { mapGetters } from 'vuex'
import FileUploader from '../../uploader/FileUploader.ts'
import Family from '@/family/Family'
import AssetComponent from '../assets/AssetComponent'

const assetHelper = new AssetHelper()

export default {
  name: 'CloudinaryUpload',
  data () {
    return {
      results: null,
      errors: [],
      file: null,
      cloudName: '',
      preset: 'pwxz7wsv',
      tags: 'browser-upload',
      progress: 0,
      showProgress: false,
      fileContents: null,
      formData: null,
      context: null,
      message: null,
      imagePreviewURL: null,
      assetType: null
    }
  },
  components: {
    AssetComponent
  },
  computed: {
    ...mapGetters({
      user: 'user',
      family: 'family'
    })
  },
  methods: {
    ImagePreview (payload) {
      console.log('Preview called')
      const file = payload // in case vuetify file input
      if (file) {
        const asset = assetHelper.createAsset(file.type)
        this.assetType = asset.type
        this.imagePreviewURL = URL.createObjectURL(file)
        URL.revokeObjectURL(file) // free memory
      } else {
        this.imagePreviewURL = null
      }
    },
    CancelPreview () {
      this.file = null
      this.imagePreviewURL = null
    },
    // function to handle form submit
    Upload: function () {
      // attach listener to be called when data from file
      if (this.file) {
        console.log('upload', this.file.name)
        this.showProgress = true
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          function () {
            this.imagePreviewURL = null
            const fileUploader = new FileUploader(this.preset)
            const requestObj = fileUploader.upload(reader)
            requestObj.onUploadProgress = function (progressEvent) {
              console.log('progress', progressEvent)
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              )
              console.log(this.progress)
            // bind "this" to access vue state during callback
            }.bind(this)
            axios(requestObj)
              .then(response => {
                this.results = response.data
                console.log(this.results)
                console.log('public_id', this.results.public_id)
                const asset = assetHelper.createAsset(
                  this.results.format,
                  this.results.public_id
                )
                const family = new Family(this.family.data.id)
                const user = new User(this.user.data.displayName, this.user.data.email, this.user.data.uid, family, this.user.data.photoURL)
                const post = new Post(this.message, user)
                post.CreatePost(
                  asset
                )
              })
              .catch(error => {
                this.errors.push(error)
                console.log(this.error)
              })
              .finally(() => {
                this.progress = 0
                this.message = null
                this.file = null
                setTimeout(
                  function () {
                    this.showProgress = false
                  }.bind(this),
                  1000
                )
              })
          }.bind(this),
          false
        )
        // call for file read if there is a file
        if (this.file && this.file.name) {
          reader.readAsDataURL(this.file)
        }
      } else {
        const family = new Family(this.family.data.id)
        const user = new User(this.user.data.displayName, this.user.data.email, this.user.data.uid, family, this.user.data.photoURL)
        const post = new Post(this.message, user)
        post.CreatePost()
        this.message = null
      }
    }
  }
}
</script>
<style lang="scss">
@import '../../assets/styles/_variables.scss';

.v-input {
  flex: 0 0 auto !important;
}

.bg-ft-upload-color{
  background-color: white;
}

.bg-ft-upload-button{
  background-color: lighten($card-color, 10%) !important;
}
.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 0px!important;
}

.v-icon.v-icon {
  font-size: 35px;
}
.v-btn--fab.v-btn--absolute, .v-btn--fab.v-btn--fixed {
    z-index: 20!important;
}
</style>
