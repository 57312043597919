







import { Component, Vue } from 'vue-property-decorator'
import CloudinaryUpload from '@/components/upload/CloudinaryUpload.vue' // @ is an alias to /src
import MobileStream from '@/components/streams/MobileStream.vue'

@Component({
  components: {
    CloudinaryUpload, MobileStream
  }
})
export default class Home extends Vue {}
