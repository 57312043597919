<template>
  <video
    :src="videoUrl"
    :autoplay="videoAutoplay"
    :controls="videoControls"
    :loop="videoLoop"
    class="mx-auto"
    :style='style'
  />
</template>

<script>

export default {
  name: 'ImageComponent',
  props: {
    videoUrl: String,
    videoAutoplay: Boolean,
    videoLoop: Boolean,
    videoControls: Boolean,
    height: String
  },
  computed: {
    style () {
      return 'height: ' + this.height ? this.height : '100%'
    }
  }

}

</script>
<style>

.v-application p {
  margin-bottom: 0px!important;
}
</style>
