
<template>
    <v-card :width="cardWidth">
      <div class="bg-specialgreen textBoxHeight">
        <p :class="GetTextSize" class="text-white p-10 pt-10">
          {{ msg }}
        </p>
      </div>
      <v-card-text class="bg-specialgreen text-white avatarBoxHeight">
          <v-row align-center>
            <v-col cols="3" align="center">
              <v-avatar size="70" class="mt-5">
                <img
                  :alt="'user'"
                  :src="avatarImgUrl"
                >
              </v-avatar>
            </v-col>
            <v-col cols="7" align="left" class="p-5">
              <p class="mt-7 text-gray-300 text-base">
                Gesendet von {{ avatarName }}
              </p>
            </v-col>
            <v-col cols="2">
              <v-icon
                v-if="visited"
                large
                color="blue"
                class="mt-6"
              >
                mdi-check
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
  name: 'NoAssetCard',
  props: {
    avatarImgUrl: String,
    avatarName: String,
    msg: String,
    cardWidth: Number,
    assetHeight: String,
    date: Object,
    visited: Boolean
  },
  computed: {
    GetTextSize () {
      return this.$route.name === 'tablet' ? 'text-4xl' : 'text-xl'
    },
    GetDate () {
      return this.date.toDate().toLocaleDateString('de-DE')
    }
  }
}
</script>
<style lang="scss">
@import '../../assets/styles/_variables.scss';

.bg-specialgreen {
  background-color: $card-color;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.textBoxHeight {
  height: 250px;
}

.avatarBoxHeight {
  height: 150px;
}

</style>
