import { Firestore } from '../../src/firebase'
import IAsset from '../cAssets/IAsset'
import IUser from '../user/IUser'
import IFamily from '../family/IFamily'

export default class Post {
  readonly message: string
  readonly user: IUser
  readonly family: IFamily

  constructor (message: string, user: IUser) {
    this.message = message
    this.user = user
    this.family = user.family
  }

  CreatePost (asset?: IAsset) {
    const postsCollection = Firestore.collection('posts')
    postsCollection
      .add({
        message: this.message,
        assetUrl: asset ? asset.GetUrl() : null,
        type: asset ? asset.type : null,
        date: new Date(),
        creatorAvatarUrl: this.user.avatarUrl,
        creatorName: this.user.name,
        creatorEmail: this.user.email,
        familyId: this.family.id,
        visited: false
      })
      .then(() => {
        console.log('Document successfully written!')
      })
      .catch((error) => {
        console.error('Error writing document: ', error)
      })
  }
}
