
export default class FileUploader {
  formData: FormData
  preset: string
  fileContents: string | ArrayBuffer | null
  file: Blob
  fileSelected: boolean

  constructor (preset: string) {
    this.formData = new FormData()
    this.preset = preset
    this.file = new Blob()
    this.fileSelected = false
    this.fileContents = ''
  }

  prepareFormData () {
    this.formData.append('upload_preset', this.preset)
    this.formData.append('file', this.fileContents ? this.fileContents.toString() : '')
    this.formData.append('resource_type', 'auto')
  }

  upload (reader: FileReader) {
    this.fileContents = reader.result
    this.fileSelected = true
    this.prepareFormData()
    const cloudinaryUploadURL = 'https://api.cloudinary.com/v1_1/da8mew2gy/upload'
    const requestObj = {
      url: cloudinaryUploadURL,
      method: 'POST',
      data: this.formData
    }
    return requestObj
  }
}
