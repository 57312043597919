
<template>
    <v-card :width="cardWidth">
      <asset-component
       :videoAutoplay="videoAutoplay"
       :videoControls="videoControls"
       :videoLoop="videoLoop"
       :height="assetHeight"
       :assetUrl="assetUrl"
       :assetType="assetType"
      />
      <v-card-text class="bg-specialgreen text-white">
          <p class="text-xl ml-3">
            {{ msg }}
          </p>
          <v-row align-center>
            <v-col cols="3" align="center">
              <v-avatar size="60" class="mt-3">
                <img
                  :alt="'user'"
                  :src="avatarImgUrl"
                >
              </v-avatar>
            </v-col>
            <v-col cols="7" align="left" class="p-5">
              <p class="mt-5 text-gray-300 text-base">
                Gesendet von {{ avatarName }}
              </p>
            </v-col>
            <v-col cols="2">
              <v-icon
                v-if="visited"
                large
                color="blue"
                class="mt-6"
              >
                mdi-check
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import AssetComponent from '../assets/AssetComponent'

export default {
  name: 'CardItem',
  props: {
    assetUrl: String,
    assetType: String,
    avatarImgUrl: String,
    avatarName: String,
    msg: String,
    cardWidth: Number,
    videoControls: Boolean,
    videoAutoplay: Boolean,
    videoLoop: Boolean,
    assetHeight: String,
    date: Object,
    visited: Boolean
  },
  components: {
    AssetComponent
  }
}
</script>
<style lang="scss">
@import '../../assets/styles/_variables.scss';

.bg-specialgreen {
  background-color: $card-color;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}
</style>
