<template>
  <v-img
    :src="GetAssetUrl"
    :width="GetWidth"
    contain
    class="grey darken-4"
  />
</template>

<script>

export default {
  name: 'ImageComponent',
  props: {
    imageUrl: String,
    height: String,
    width: String
  },
  computed: {
    GetHeight () {
      return this.height ? this.height : '100%'
    },
    GetWidth () {
      return this.width ? this.width : '100%'
    },
    GetAssetUrl () {
      return this.imageUrl.replace('upload', 'upload' + '/' + 'w_1000,h_480,c_limit')
    }
  }
}

</script>
