
<template>
    <v-layout class="justify-center">
      <div v-if="assetType === 'video' || assetType === 'image' ">
        <asset-card
          :assetUrl="assetUrl"
          :assetType="assetType"
          :avatarImgUrl="avatarImgUrl"
          :avatarName="avatarName"
          :msg="msg"
          :cardWidth="cardWidth"
          :videoAutoplay="videoAutoplay"
          :videoControls="videoControls"
          :videoLoop="videoLoop"
          :assetHeight="assetHeight"
          :date="date"
          :visited="visited"
        />
      </div>
      <div v-else-if="assetType === null">
        <no-asset-card
          :avatarImgUrl="avatarImgUrl"
          :avatarName="avatarName"
          :msg="msg"
          :cardWidth="cardWidth"
          :assetHeight="assetHeight"
          :date="date"
          :visited="visited"
        />
      </div>
      <div v-else>
      </div>
    </v-layout>
</template>

<script>

import AssetCard from '../cards/AssetCard.vue'
import NoAssetCard from '../cards/NoAssetCard.vue'

export default {
  name: 'CardItem',
  props: {
    assetUrl: String,
    assetType: String,
    avatarImgUrl: String,
    avatarName: String,
    msg: String,
    cardWidth: Number,
    videoControls: Boolean,
    videoAutoplay: Boolean,
    videoLoop: Boolean,
    assetHeight: String,
    date: Object,
    visited: Boolean
  },
  components: {
    AssetCard, NoAssetCard
  }
}
</script>
<style>
.v-application ul, .v-application ol {
  padding-left: 0px !important;
}
</style>
