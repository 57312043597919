
<template>
  <div class="itemList">
    <v-sheet
      :color="`grey lighten-4`"
      class="pa-3"
      v-if="pageNotLoaded"
    >
      <v-skeleton-loader
        class="mx-auto mt-10"
        max-width="300"
        type="card-avatar"
        light
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto mt-10"
        max-width="300"
        type="card-avatar"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto mt-10"
        max-width="300"
        type="card-avatar"
      ></v-skeleton-loader>
    </v-sheet>
    <ul>
      <li v-for="post in posts" :key="post.public_id" class="mb-5">
        <card-item
          :assetUrl="post.assetUrl"
          :assetType="post.type"
          :avatarImgUrl="post.creatorAvatarUrl"
          :avatarName="post.creatorName"
          :msg="post.message"
          :cardWidth="380"
          :videoAutoplay="false"
          :videoControls="true"
          :videoLoop="false"
          :date="post.date"
          :visited="post.visited"
        />
      </li>
    </ul>
    <div v-if="posts.length > 0" v-intersect="LoadMorePosts"/>
    <div v-if="posts.length === 0">
      <empty-card>
      </empty-card>
    </div>
  </div>
</template>

<script>

import CardItem from '../cards/CardItem.vue'
import EmptyCard from '../cards/EmptyCard'
import { Firestore } from '../../firebase'
import { mapGetters } from 'vuex'

export default {
  components: {
    CardItem,
    EmptyCard
  },
  data () {
    return {
      posts: [],
      response: null,
      lastVisiblePost: null,
      pageNotLoaded: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      family: 'family'
    })
  },
  mounted () {
    this.GetPosts()
  },
  methods: {
    GetPosts () {
      this.GetDocuments()
        .onSnapshot(querySnapshot => {
          this.posts = []
          querySnapshot.forEach(doc => {
            this.posts.push(doc.data())
          })
          this.lastVisiblePost = querySnapshot.docs[querySnapshot.docs.length - 1]
          this.pageNotLoaded = false
        })
    },
    LoadMorePosts () {
      console.log('Loading more posts')
      this.GetDocuments()
        .startAfter(this.lastVisiblePost)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.posts.push(doc.data())
            this.lastVisiblePost = doc
          })
        })
    },
    GetDocuments () {
      if (this.user.loggedIn && this.family.data.id !== null && this.family.data.id !== '') {
        return Firestore
          .collection('posts')
          .where('familyId', '==', this.family.data.id)
          .orderBy('date', 'desc')
          .limit(5)
      }
    }
  }
}
</script>
