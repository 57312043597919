import IAsset from './IAsset'

export default class CImage implements IAsset {
  readonly Id: string | null
  readonly type: string
  readonly date: Date

  constructor (
    Id?: string,
    date: Date = new Date()
  ) {
    this.Id = Id || null
    this.type = 'image'
    this.date = date
  }

  GetUrl (): string {
    if (!this.Id) {
      return ''
    }
    return 'https://res.cloudinary.com/da8mew2gy/image/upload/' + this.Id
  }
}
